import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "icon-bww-logo.png" }) {
        childImageSharp {
          fixed(width: 275) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.icon.childImageSharp.fixed} className="icon" />
}

export default Image
