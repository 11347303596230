import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Nav from "react-bootstrap/Nav"

import config from "../utils/siteConfig"

import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share"

const PageShare = () => {
  const pageUrl = config.siteUrl + "/"
  const shareImage = config.siteUrl + config.shareImage

  // DECLARE IMAGES
  const socialIcons = useStaticQuery(graphql`
    query {
      twitterIcon: file(relativePath: { eq: "icon-share-tw.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      facebookIcon: file(relativePath: { eq: "icon-share-fb.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div id="page-share">
      <Nav
        style={{
          justifyContent: `center`,
        }}
      >
        <Nav.Link eventKey="twitter">
          <TwitterShareButton
            url={pageUrl}
            title={config.shareTwitterDescription}
            via="BWWings"
            hashtags={["footballprenup"]}
            additionalProps={{
              "aria-label": "Twitter share",
            }}
          >
            <Img fixed={socialIcons.twitterIcon.childImageSharp.fixed} />
          </TwitterShareButton>
        </Nav.Link>

        <Nav.Link eventKey="facebook">
          <FacebookShareButton
            url={pageUrl}
            quote={config.shareDescription}
            image={shareImage}
            hashtag="#footballprenup"
            additionalProps={{
              "aria-label": "Facebook share",
            }}
          >
            <Img fixed={socialIcons.facebookIcon.childImageSharp.fixed} />
          </FacebookShareButton>
        </Nav.Link>
      </Nav>
    </div>
  )
}

export default PageShare
