import React from "react"

// import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import PageShare from "./PageShare"

const Footer = () => (
  <footer>
    <Row>
      <Col sm={12}>
        <p>Spread the word</p>
      </Col>
      <Col sm={12}>
        <PageShare />
      </Col>
    </Row>
    <Row className="brand-links">
      <Col sm={12}>
        <p>
          <a
            href="//www.buffalowildwings.com/"
            target="_blank"
            rel="noopener noreferrer">
              buffalowildwings.com
          </a>
          <a
            href="//www.buffalowildwings.com/en/privacy-policy-terms/"
            target="_blank"
            rel="noopener noreferrer">
              Terms &amp; Conditions
          </a>
        </p>
      </Col>
    </Row>
  </footer>
)

export default Footer
