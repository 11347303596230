import React from "react"
import { Link } from "gatsby"
import { PDFDownloadLink } from '@react-pdf/renderer';
import { connect } from 'react-redux'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import LogoIcon from "../components/bwwLogoIcon"
import ShieldIcon from "../components/shieldIcon"
// import NotaryIcon from "../components/notaryIcon"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import IconArrowL from "../images/icon-arrow-left-inline.svg"
import { navigate } from "@reach/router";

import { MyDocument } from './prenup';
import {
  getGamePairs,
  getTradeOffs,
  getFavoriteTeamName,
  getPartnerName,
  getFanName,
} from "../datastore/form-data"

import Nav from "react-bootstrap/Nav"
import {
  TwitterShareButton,
} from "react-share"

import config from "../utils/siteConfig"

export class DownloadPageComponent extends React.Component {

    render() {
        return (
          <Layout>
            <SEO title="Download Prenup" />
            <div id="download">
              <div id="section-confirmation">
                <div id="corner-triangle" onClick={event => navigate("/")}>
                  <div className="corner-triangle-text text-capitalize">
                    <div className="corner-triangle-firstline">
                      <IconArrowL />
                    </div>
                  </div>
                </div>
                <Container>
                  <Row className="justify-content-center">
                    <Col md={10} sm={12} className="text-center">
                      <ShieldIcon />
                      <h1>You Did It!</h1>
                      <p>
                        You saved football. And your relationship. *high five*
                        Now take your Football Prenup and have the best season
                        ever.
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <button className="btn btn-yellow">
                        {typeof window !== 'undefined'
                          ? <PDFDownloadLink
                          document={<MyDocument {...this.props.pdfProps} />}
                          fileName="BuffaloWildWings_Football_Prenup_Personalized_Form.pdf"
                          style={{
                            color: "black",
                            textDecoration: "none",
                          }}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Making PDF..." : "Print It"
                          }
                        </PDFDownloadLink>
                        : null}
                      </button>
                      <button className="btn btn-yellow btn-prenup-twitter">
                        <Nav.Link eventKey="twitter">
                          <TwitterShareButton
                            url={config.siteUrl + "/"}
                            title={config.shareDownloadDescription}
                            via="BWWings"
                            hashtags={["footballprenup"]}
                            additionalProps={{
                              "aria-label": "Twitter share",
                            }}
                          >
                            Share It
                          </TwitterShareButton>
                        </Nav.Link>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <hr />
                      <p>Want to get even more personal with your Prenup?</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <button className="btn btn-dark">
                        <a
                          href="/BuffaloWildWings_Football_Prenup_Form.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download Blank PDF
                        </a>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* <div id="section-notary">
                <Container>
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <NotaryIcon />
                      <h1>DON’T PLAY.</h1>
                      <p>
                        Take your Football Prenup to the next level with local
                        notary services.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <button className="btn btn-yellow">
                        <a
                          href="//www.google.com/maps/search/notary+near+me"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Find A Notary
                        </a>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div> */}
              <div id="section-locations">
                <Container>
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <LogoIcon />
                      <h1>YOU HEAR GROWLING?</h1>
                      <p>
                        Saving football makes us hungry. Let’s celebrate your
                        Football Prenup.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <button className="btn btn-yellow">
                        <a
                          href="//www.buffalowildwings.com/locations"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Find Your B-Dubs
                        </a>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Footer />
            </div>
          </Layout>
        )
    }
}

const mapStateToProps = state => ({
  pdfProps: {
    gamePairs: getGamePairs(state),
    tradeOffs: getTradeOffs(state),
    favoriteTeamName: getFavoriteTeamName(state),
    partnerName: getPartnerName(state),
    fanName: getFanName(state),
  }
})

const DownloadPage = connect(mapStateToProps)(DownloadPageComponent);

export default DownloadPage